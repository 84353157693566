import React from 'react';
import './Experience.css';

function Experience() {
  return (
    <section id="experience" className="experience">
      <h2>Experience</h2>
      <div className="experience-item">
        <h3>Marketing Intern</h3>
        <p className="experience-duration">AM/PM Towing, Richmond, CA — Jun 2024 - Aug 2024</p>
        <ul>
          <li>Streamlined office documentation, enhancing productivity and efficiency.</li>
          <li>Managed confidential records, ensuring secure and organized documentation.</li>
          <li>Created engaging content, including video and graphics, using tools like Photoshop.</li>
        </ul>
      </div>
    </section>
  );
}

export default Experience;
