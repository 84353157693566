import React from 'react';
import './Header.css';

function Header() {
  return (
    <header className="header">
      <h1 className="header-title">Zayd Azam</h1>
      <p className="header-subtitle">Aspiring Product Marketing Manager</p>
    </header>
  );
}

export default Header;
