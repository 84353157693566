import React from 'react';
import './Skills.css';

function Skills() {
  return (
    <section id="skills" className="skills">
      <h2>Skills</h2>
      <ul className="skills-list">
        <li>Time Management</li>
        <li>Confidentiality</li>
        <li>Record Keeping</li>
        <li>Office Organization</li>
        <li>Video Editing</li>
        <li>Content Creation</li>
        <li>Photoshop</li>
        <li>Problem Solving</li>
        <li>Adaptability</li>
        <li>Critical Thinking</li>
        <li>Research Skills</li>
      </ul>
    </section>
  );
}

export default Skills;
