import React from 'react';
import './About.css';

function About() {
  return (
    <section id="about" className="about">
      <h2>About Me</h2>
      <p>
        Hello! I’m Zayd Azam, a highly motivated and adaptable individual with a strong foundation in communication and marketing. Currently pursuing a Bachelor’s degree in Communications with a focus on marketing at UCSD, I aim to leverage my skills in product marketing to make a meaningful impact.
      </p>
      <p>
        With experience as a Marketing Intern, I have developed expertise in managing confidential records, organizing office systems, and creating engaging content. My ultimate goal is to become a Product Marketing Manager at a leading tech company, where I can blend creativity with strategic thinking to drive brand success.
      </p>
    </section>
  );
}

export default About;
